import React from "react";

const certContents = [
  { certImage: "https://images.credly.com/size/340x340/images/df83f35a-8628-4baa-8a30-f5c03b3b53cf/image.png", certName: "AWS Authorize Instructor", certUrl: "https://www.credly.com/badges/bb3734a0-27bc-46c8-8d59-a59ea0ab46ec"},
  { certImage: "https://images.credly.com/size/340x340/images/b9feab85-1a43-4f6c-99a5-631b88d5461b/image.png", certName: "AWS Developer Associate" , certUrl: "https://www.credly.com/badges/7de0279c-2989-4cf0-8514-b6384cf31e59"},
  { certImage: "https://images.credly.com/size/340x340/images/0e284c3f-5164-4b21-8660-0d84737941bc/image.png", certName: "AWS Solution Architect Associate", certUrl: "https://www.credly.com/badges/f0dadd3e-8a54-4fd4-a39d-da9bc6644639" },
  { certImage: "https://images.credly.com/size/340x340/images/00634f82-b07f-4bbd-a6bb-53de397fc3a6/image.png", certName: "AWS Cloud Practitioner", certUrl: "https://www.credly.com/badges/f0dadd3e-8a54-4fd4-a39d-da9bc6644639" }
];

const zoom = {
    cursor: "zoom-in"
}

const Certificates = () => {

  return (
    <>
      {certContents.map((val, i) => (
        <div className="col-6 col-md-3 mb-3 mb-sm-5" style={zoom} key={i}>
            <figure onClick={()=>window.open( val.certUrl, "_blank")}>
                <img src={val.certImage} alt="Portolio" />
            </figure>
        </div>
      ))}
    </>
  );
};

export default Certificates;

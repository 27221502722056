import React from "react";

function getAge(){
  let currentYear = new Date().getFullYear()
  return (currentYear - 1993) + " years";
}

const zoom = {
  cursor: "zoom-in"
}

const personalInfoContent = [
  { meta: "first name", metaInfo: "Toni" , metaUrl: ""},
  { meta: "last name", metaInfo: "Kusnandar" , metaUrl: ""},
  { meta: "Age", metaInfo: getAge() , metaUrl: ""},
  { meta: "Nationality", metaInfo: "Indonesian" , metaUrl: ""},
  { meta: "Freelance", metaInfo: "Available" , metaUrl: ""},
  { meta: "Address", metaInfo: "Bogor" , metaUrl: ""},
  { meta: "phone", metaInfo: "+6282213672972" , metaUrl: ""},
  { meta: "Email", metaInfo: "toni@kusnandar.dev" , metaUrl: "mailto:toni@kusnandar.dev"},
  { meta: "Linkedin", metaInfo: "kusnandartoni" , metaUrl: "https://www.linkedin.com/in/kusnandartoni/"},
  { meta: "langages", metaInfo: "Bahasa, English" , metaUrl: ""},
];

const PersonalInfo = () => {
  return (
    <ul className="about-list list-unstyled open-sans-font">
      {personalInfoContent.map((val, i) => (
        <li key={i}>
          <span className="title">{val.meta}: </span>
          {val.metaUrl.length > 0 &&
          <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block" style={zoom} onClick={() => val.metaUrl && window.open(val.metaUrl)}>
            {val.metaInfo}
          </span>
          }
          {val.metaUrl.length === 0 &&
          <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
            {val.metaInfo}
          </span>
          }

        </li>
      ))}
    </ul>
  );
};

export default PersonalInfo;
